import { useAgendasStore } from "@/stores/models-agendas";
import { useEventsStore } from "@/stores/models-events";
import { useSearchStore } from "@/stores/search";

export default function useMultipleAgendas() {
  const agendasStore = useAgendasStore();
  const searchStore = useSearchStore();
  const eventsStore = useEventsStore();

  function updateMultipleAgenda(agendaId, temporary = false) {
    if (agendaId && temporary) {
      return eventsStore.setTemporaryAgendaId(agendaId);
    }

    if (agendaId) {
      // NOTE: One agenda is selected
      // NOTE: Set preferredCalendarView and display it by default
      agendasStore.currentAgendaPreferredCalendarView =
        agendasStore.getAgendaCalendarView(agendaId);

      if (agendasStore.currentAgendaPreferredCalendarView) {
        searchStore.updateCalendarCurrentView(agendasStore.currentAgendaPreferredCalendarView);
      } else {
        searchStore.updateCalendarCurrentView("listView");
      }

      // ag...
      return eventsStore.setCurrentAgendaId(agendaId);
    } else {
      // NOTE:  On agenda change to all, go to multipleAgendaTableView by default
      searchStore.updateCalendarCurrentView("multipleAgendaTableView");
      // ag...
      return eventsStore.setCurrentAgendaId(0);
    }
  }

  return {
    updateMultipleAgenda,
  };
}
