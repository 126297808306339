import { defineStore } from "pinia";
import { ref } from "vue";
import { useInitialTourStore } from "./initial-tour";

export const useHeaderStore = defineStore("headerStore", () => {
  const mySettingsMenu = ref(null);

  function closeMySettingsMenu() {
    const { initialTourCurrentStepIs } = useInitialTourStore();
    if (!mySettingsMenu.value || initialTourCurrentStepIs(1) || initialTourCurrentStepIs(4)) return;

    if (mySettingsMenu.value.classList.contains("show")) {
      mySettingsMenu.value.classList.remove("show");
      mySettingsMenu.value.classList.add("hide");
    }
  }

  function openMySettingsMenu() {
    if (!mySettingsMenu.value) return;

    if (mySettingsMenu.value.classList.contains("hide")) {
      mySettingsMenu.value.classList.remove("hide");
      mySettingsMenu.value.classList.add("show");
    } else if (mySettingsMenu.value.classList.contains("show")) {
      mySettingsMenu.value.classList.remove("show");
      mySettingsMenu.value.classList.add("hide");
    } else {
      mySettingsMenu.value.classList.add("show");
    }
  }

  return { mySettingsMenu, closeMySettingsMenu, openMySettingsMenu };
});
