import axios from "axios";
import qs from "qs";

import { Links } from "@/api/links.js";

export class Events {
  getAll(
    searchInput = null,
    dateStartAfter = null,
    dateStartBefore = null,
    dateEnd = null,
    agendaId = null,
    pagination = true,
    page = 1,
    itemsPerPage = 10,
    order = "desc",
    timeFilter = null
  ) {
    const params = {};

    if (dateStartAfter) {
      params["dateStart"] = {};
      params["dateStart"]["after"] = dateStartAfter;
    }

    if (dateStartAfter && dateStartBefore) {
      params["dateStart"]["before"] = dateStartBefore;
    } else if (!dateStartAfter && dateStartBefore) {
      params["dateStart"] = {};
      params["dateStart"]["before"] = dateStartBefore;
    }

    if (dateEnd) {
      params["dateEnd"] = {};
      params["dateEnd"]["before"] = dateEnd;
    }
    if (!pagination) {
      params["pagination"] = false;
    } else {
      params["page"] = page;
      params["itemsPerPage"] = itemsPerPage;
    }

    if (searchInput) {
      params["searchInput"] = searchInput;
    }

    if (!agendaId == null) {
      throw new Error("Missing agendaId");
    }
    params["agendaId"] = agendaId;

    params["order[dateStart]"] = order;

    if (timeFilter) {
      params["timeFilter"] = {};
      params["timeFilter"]["filter"] = timeFilter.filter;
      params["timeFilter"]["startOfDay"] = timeFilter.startOfDay;
      params["timeFilter"]["endOfDay"] = timeFilter.endOfDay;
    }

    return axios.get(Links.EVENTS_LINK() + "?" + qs.stringify(params));
  }
  get(id) {
    return axios.get(Links.EVENT_LINK(id));
  }
  post(item) {
    return axios.post(Links.EVENTS_LINK(), item);
  }
  put(id, item) {
    return axios.put(Links.EVENT_LINK(id), item);
  }
  delete(payload) {
    return axios.delete(Links.EVENT_LINK(payload.id), {
      data: {
        agendaId: payload.agendaId,
      },
    });
  }
  getEventCommunicationsHistory(eventId) {
    return axios.get(Links.EVENT_GET_SMS_HISTORY(eventId));
  }
  duplicate(eventId, data) {
    return axios.post(Links.EVENT_DUPLICATE_LINK(eventId), data);
  }
  changePaymentStatus(id, data) {
    return axios.post(Links.EVENT_CHANGE_PAYMENT_STATUS_LINK(id), data);
  }
  createInvoice(id) {
    return axios.get(Links.EVENT_LINK(id) + "/create-invoice");
  }
  removeEventType(id, eventTypeId) {
    const params = {};
    params["eventTypeId"] = eventTypeId;
    return axios.get(Links.EVENT_LINK(id) + "/remove-event-type?" + qs.stringify(params));
  }
}

export const APIEvents = new Events();
