import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";

import { useCompaniesStore } from "@/stores/models-companies.js";

import useAlert from "@/hooks/alert";
import { useHeaderStore } from "@/stores/header";
import useMultipleAgendas from "@/hooks/multiple-agendas";
import { useAgendasStore } from "./models-agendas";

export const useInitialTourStore = defineStore("initialTour", () => {
  const initialTour = ref(undefined);

  function setInitialTour(tour) {
    initialTour.value = tour;
  }

  const { alertError } = useAlert();

  const companiesStore = useCompaniesStore();

  async function completeInitialTour() {
    if (!initialTour.value) return;

    try {
      initialTour.value.finish();
      stopObserver();

      await companiesStore.completeInitialTour();
    } catch (err) {
      console.error(err);
      alertError();
    }
  }

  function startInitialTour() {
    if (!initialTour.value) return;

    initialTour.value.start();
    startObserver();
  }

  const options = {
    useKeyboardNavigation: false,
    labels: {
      buttonSkip: "Oprește",
      buttonPrevious: "Înapoi",
      buttonNext: "Continuă",
      buttonStop: "Finalizează!",
    },
  };

  const router = useRouter();

  // HACK: !!!: wait for the element to be rendered on the page before attaching the popup to it
  function waitForElement(selector, timeLimit = 10000) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
      } else {
        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            clearTimeout(timeoutID);
          }
        });

        observer.observe(document.body, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        const timeoutID = setTimeout(() => {
          reject();
          observer.disconnect();
        }, timeLimit);
      }
    });
  }

  // HACK: !!!: to trigger popper instance update (by resize event)
  let vueTourNode;
  const observerTargetNode = document.getElementById("app");
  const observerConfig = { attributes: true, childList: true, subtree: true };
  const observerCallback = mutationList => {
    for (const mutation of mutationList) {
      const mutationTarget = mutation.target;

      // !!!: ignore vue tour elements mutations to avoid infinite loops
      if (!vueTourNode.contains(mutationTarget)) window.dispatchEvent(new Event("resize"));
    }
  };
  const observer = new MutationObserver(observerCallback);
  function startObserver() {
    vueTourNode = document.getElementById("initial-tour");
    observer.observe(observerTargetNode, observerConfig);
  }
  function stopObserver() {
    observer.disconnect();
  }

  // HACK: !!!: hide vue tour step elements until their target elements are fully rendered (avoid page jumps of the elements)
  const viewStepElements = ref(false);
  function initialTourNextStep() {
    if (!initialTour.value) return;

    viewStepElements.value = false;
    initialTour.value.nextStep();
  }
  function delayViewStepElements() {
    setTimeout(() => {
      viewStepElements.value = true;
    }, 500);
  }

  const headerStore = useHeaderStore();
  const { openMySettingsMenu } = headerStore;

  const hasMultipleAgenda = computed(() => companiesStore.hasMultipleAgenda);
  const { updateMultipleAgenda } = useMultipleAgendas();
  const agendasStore = useAgendasStore();

  function actionWhenMultipleAgendas() {
    if (hasMultipleAgenda.value) {
      updateMultipleAgenda(agendasStore.getFirst?.id);
    }
  }

  // HACK: to open my settings menu dropdown on small screens
  function openMySettingsMenuIfCase() {
    return new Promise((resolve, reject) => {
      try {
        // NOTE: if side menu selector is present, don't wait for the header menu selector. it means that the sidebar is visible.
        const sidebarMenuSelector = document.querySelector(
          '[data-initial-tour-element="sidebar-menu-selector"]'
        );
        if (sidebarMenuSelector) resolve();

        // NOTE: wait for the element, it might not be on the page when coming from certain routes
        waitForElement('[data-initial-tour-element="header-menu-selector"]', 5000)
          .then(() => {
            setTimeout(() => {
              openMySettingsMenu();
              resolve();
            }, 0);
          })
          .catch(() => resolve());
      } catch (err) {
        reject(err);
      }
    });
  }

  const steps = [
    /** STEP 1 */
    {
      target: '[data-initial-tour-step="1"]',
      content: `Serviciile tale sunt aici!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.events" })
            .then(() => {
              actionWhenMultipleAgendas();

              return openMySettingsMenuIfCase();
            })
            .then(() => {
              return waitForElement('[data-initial-tour-step="1"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    /** STEP 2 */
    {
      target: '[data-initial-tour-step="2"]',
      content: `Adăuga un serviciu nou!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.eventTypes" })
            .then(() => {
              return waitForElement('[data-initial-tour-step="2"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    /** STEP 3 */
    {
      target: '[data-initial-tour-step="3"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      params: {
        highlight: true,
        // ???: ??
        // placement: "top",
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="2"]');
            el.click();

            waitForElement('[data-initial-tour-step="3"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
    /** STEP 4 */
    {
      target: '[data-initial-tour-step="4"]',
      // content in template
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            openMySettingsMenuIfCase()
              .then(() => {
                return waitForElement('[data-initial-tour-step="4"]');
              })
              .then(el => {
                delayViewStepElements();
                resolve(el);
              });
          } catch {
            reject();
          }
        }),
    },
    /** STEP 5 */
    {
      target: '[data-initial-tour-step="5"]',
      // content in template
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.objects.list" })
            .then(() => {
              return waitForElement('[data-initial-tour-step="5"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    /** STEP 6 */
    {
      target: '[data-initial-tour-step="6"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="5"]');
            el.click();

            waitForElement('[data-initial-tour-step="6"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
    /** STEP 7 */
    {
      target: '[data-initial-tour-step="7"]',
      content: `Adaugă o programare nouă!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            router
              .push({ name: "route.events" })
              .then(() => {
                actionWhenMultipleAgendas();

                return waitForElement('[data-initial-tour-step="7"]');
              })
              .then(el => {
                delayViewStepElements();
                resolve(el);
              })
              .catch(() => {
                reject();
              });
          } catch {
            reject();
          }
        }),
    },
    /** STEP 8 */
    {
      target: '[data-initial-tour-step="8"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="7"]');
            el.click();

            waitForElement('[data-initial-tour-step="8"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
  ];

  function conditionallyToInitialTourNextStep(step) {
    if (initialTour?.value?.currentStep === step - 2) {
      initialTourNextStep();
      return true;
    } else {
      return false;
    }
  }

  function initialTourCurrentStepIs(step) {
    return initialTour?.value?.currentStep === step - 1;
  }
  function initialTourCurrentStepIsNot(step) {
    return initialTour?.value?.currentStep !== step - 1;
  }

  return {
    initialTour,
    setInitialTour,

    initialTourOptions: options,
    initialTourSteps: steps,

    startInitialTour,
    completeInitialTour,

    initialTourNextStep,
    viewStepElements,

    conditionallyToInitialTourNextStep,
    initialTourCurrentStepIs,
    initialTourCurrentStepIsNot,
  };
});
